<template>
    <NuxtLayout name="default">
        <template #override-main>
            <CoreContainer>
                <BaseContainerContent class="flex items-start gap-8" :padding="{ top: 'none' }">

                    <UiCard tag="aside" class="sim-user-navigation hidden lg:block">
                        <template #header>
                            <div class="sim-text-large sim-font-bold mb-3">
                                {{ $t('user.my_account') }}
                            </div>
                        </template>

                        <nav>
                            <ul>
                                <li v-for="link in appConfig.userMenu.links"
                                    :key="link.routeName"
                                    class="sim-user-navigation__item"
                                >
                                    <NuxtLink
                                        :to="localePath(link.routeName)"
                                        class="sim-user-navigation__link sim-text-normal sim-font-regular"
                                    >
                                        {{ $t(link.labelPath) }}
                                    </NuxtLink>
                                </li>
                            </ul>
                        </nav>
                    </UiCard>

                    <main id="main" class="w-full">
                        <slot />
                    </main>
                </BaseContainerContent>
            </CoreContainer>
        </template>
    </NuxtLayout>
</template>

<script lang="ts" setup>

const appConfig = useAppConfig()
const localePath = useLocalePath()

</script>

<style lang="scss" scoped>
$side-padding: 3rem;

.sim-user-navigation {
    width: min(22rem, 100%);
    padding: 1.5rem 3rem;
    box-sizing: border-box;
}

.sim-user-navigation__item {
    margin-inline: - $side-padding;
}

.sim-user-navigation__link {
    display: block;
    padding: 0.5rem $side-padding;
    transition: background-color $sim-trans-time-normal $sim-timing;

    &.router-link-active {
        @include sim-font-bold;
    }

    &:hover {
        background-color: $sim-c-basic-300;
        transition-duration: $sim-trans-time-short;
    }
}

</style>
